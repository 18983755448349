
import GraphControls from '@/components/Graphs/GraphControls/GraphControls.vue';
import LeftPane from '@/components/Search/LeftPane.vue';
import GraphHelp from '@/components/Help/GraphHelp.vue';
import PrivacyPolicyImprintLinks from '@/components/legal/PrivacyPolicyImprintLinks.vue';
import {
  AnalysisResponse,
  AnalysisType,
  AuthorAnalysisType,
  Community,
  GraphDrawingLayout,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import fruchtermanReingold, {
  FruchtermanReingoldLayoutOptions,
} from '@ambalytics/graphology-layout-fruchtermanreingold/worker';
import { LabelSelector, Layout, NodeType, WebGraph } from '@ambalytics/webgraph';
import { schemeTableau10 } from 'd3-scale-chromatic';
import Graph, { UndirectedGraph } from 'graphology';
import { circlepack, circular, random } from 'graphology-layout';
import FA2Layout, { FA2LayoutSupervisorParameters } from 'graphology-layout-forceatlas2/worker';
import modularity from 'graphology-metrics/modularity';
import { Attributes, SerializedEdge, SerializedNode } from 'graphology-types';
import {
  computed,
  ComputedRef,
  defineComponent,
  onBeforeUnmount,
  onUnmounted,
  PropType,
  provide,
  ref,
  toRefs,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import AnalysisWorker from 'worker-loader!./analysis.worker';
import EntitiesWorker from 'worker-loader!./entities.worker';
import EntitiesRefsWorker from 'worker-loader!./entitiesRefs.worker';
import * as AnalysisWorkerNS from './analysis.worker';
import * as EntitiesWorkerNS from './entities.worker';
import * as EntitiesRefsWorkerNS from './entitiesRefs.worker';

const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

const percentageToHexColor = (x: number) => hslToHex(x * 360, 33, 66);

interface InternalAnalysisMapping {
  disableBackdrop?: boolean;
  disableEdges?: boolean;
  colorMap?: readonly string[];
}

interface InternalLayoutMapping {
  layout: Layout;
  options?: unknown;
}

export default defineComponent({
  name: 'SearchGraph',
  props: {
    entities: {
      type: Object as PropType<SearchResponse[]>,
      required: true,
    },
  },
  components: {
    GraphHelp,
    GraphControls,
    PrivacyPolicyImprintLinks,
    LeftPane,
  },
  setup(props) {
    const { entities } = toRefs(props);
    const store = useStore();
    const webGraphContainer = ref<null | HTMLDivElement>(null);
    const contextMenuContainer = ref<null | HTMLDivElement>(null);
    const nodeInfoBoxContainer = ref<null | HTMLDivElement>(null);

    const nodeLimits = ref<number[]>([]);

    const webGraph = ref<null | WebGraph>(null);
    const graph = ref<null | UndirectedGraph>(null);

    const selectedEntityId = computed(() => store.getters['search/selectedEntityId'] as number[]);

    const currentAnalysis = computed(() => store.getters['search/currentAnalysis'] as AnalysisType);
    const analysis = computed(() => store.getters['search/analysis']() as AnalysisResponse[]);

    const analysislayoutConfigurations: { [key in AnalysisType]: InternalAnalysisMapping } = {
      [PublicationAnalysisType.CITATIONNETWORK]: {
        disableEdges: false,
        disableBackdrop: true,
        colorMap: schemeTableau10,
      },
      [PublicationAnalysisType.KNOWLEDGEBASES]: {
        disableEdges: false,
        // See: https://colorbrewer2.org/#type=qualitative&scheme=Pastel1&n=9
        colorMap: schemeTableau10,
      },
      [PublicationAnalysisType.RESEARCHFRONTS]: {
        disableEdges: true,
        // See: https://colorbrewer2.org/#type=qualitative&scheme=Paired&n=9
        colorMap: schemeTableau10,
      },
      [PublicationAnalysisType.HYBRIDFRONTS]: {
        disableEdges: true,
        // See: https://colorbrewer2.org/#type=qualitative&scheme=Set3&n=9
        colorMap: schemeTableau10,
      },
      [AuthorAnalysisType.COAUTHORNETWORK]: {
        disableEdges: true,
        colorMap: schemeTableau10,
      },
    };

    const backdropActive = ref(
      !analysislayoutConfigurations[currentAnalysis.value].disableBackdrop
    );
    const allEdges = ref(false);

    const clusters = ref<Community[]>([]);
    const clusterColors = computed(() => {
      const colorMap = analysislayoutConfigurations[currentAnalysis.value].colorMap;
      if (!colorMap) {
        return { [-1]: '#ffffff' } as Record<number, string>;
      } else {
        if (!clusters.value || clusters.value.length <= 0) {
          return { [-1]: '#ffffff' } as Record<number, string>;
        }
        // If there are more clusters than colors in the color map -> generate colormap with hsl
        // else use colormap
        const getColor = (idx: number, length: number) =>
          idx >= colorMap.length ? percentageToHexColor(idx / length) : colorMap[idx];

        // Using negative id for not existing clusters
        const colors: Record<number, string> = {};

        for (let i = 0; i < clusters.value.length; i++) {
          // Do not add color for cluster with negative id (nodes with no cluster)
          const id = parseInt(clusters.value[i].id);
          if (id === -1) colors[-1] = '#ffffff';
          else colors[id] = getColor(i, clusters.value.length);
        }
        return colors;
      }
    });

    const layoutConfigurations: { [key in GraphDrawingLayout]: InternalLayoutMapping } = {
      [GraphDrawingLayout.FRUCHTERMANREINGOLD]: {
        layout: fruchtermanReingold,
        options: {
          iterations: 100,
          skipUpdates: 49,
          // edgeWeightInfluence: 20,
          C: 5,
          speed: 4,
          // gravity: 10,
        } as Partial<FruchtermanReingoldLayoutOptions>,
      },
      [GraphDrawingLayout.CIRCLEPACK]: {
        layout: circlepack,
      },
      [GraphDrawingLayout.CIRCULAR]: {
        layout: circular,
      },
      [GraphDrawingLayout.RANDOM]: {
        layout: random,
      },
      [GraphDrawingLayout.FORCEATLAS2]: {
        layout: (layoutGraph: Graph, options: FA2LayoutSupervisorParameters) => {
          // first apply another layout where nodes of each cluster are grouped
          // (better results for fa2)
          const intervalDivisor = (clusters.value ? clusters.value.length : 0) + 1;
          layoutGraph.forEachNode((node) => {
            const cluster = layoutGraph.getNodeAttribute(node, 'cluster');

            const interval = 1 / intervalDivisor;
            const angle = ((cluster + 1) * interval + Math.random() * interval) * 360;

            const pos = {
              x: Math.sin(angle * (Math.PI / 180)),
              y: Math.cos(angle * (Math.PI / 180)),
            };

            layoutGraph.mergeNode(node, pos);
          });

          const fa2Worker = new FA2Layout(layoutGraph, options);

          // ! The modularity function throws on graphs without edges.
          // In this case we use a modularity = 1
          const graphModularity =
            layoutGraph.size === 0
              ? 1
              : modularity(layoutGraph, {
                  attributes: { community: 'cluster' },
                  weighted: true,
                });

          fa2Worker.start();

          // ! Set timeout for stopping worker after first update (first iteration)
          graph.value?.once('eachNodeAttributesUpdated', () => {
            // TODO better calculation of duration based on metrics like modularity
            setTimeout(() => {
              fa2Worker.stop();
              fa2Worker.kill();
            }, Math.min(3500, (1 / graphModularity > 0 ? graphModularity : 1) * 1000));
          });
        },
        options: {
          settings: {
            adjustSizes: true,
            barnesHutOptimize: true,
            edgeWeightInfluence: 2,
            gravity: 5,
          },
        } as FA2LayoutSupervisorParameters,
      },
    };
    const currentLayout = ref(GraphDrawingLayout.FORCEATLAS2);

    const addEntities = (entities: SearchResponse[]) => {
      return new Promise<void>((resolve) => {
        // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
        const copiedEntities = JSON.parse(JSON.stringify(entities));

        // TODO: As soon as we get normalized values we can set the limit for the score to 0 and 1.
        const limits = entities.reduce(
          ({ score: scoreLimits, year: yearLimits }, { score, entity }) => {
            return {
              score: [Math.min(scoreLimits[0], score), Math.max(scoreLimits[1], score)],
              year: [Math.min(yearLimits[0], entity.year), Math.max(yearLimits[1], entity.year)],
            };
          },
          {
            score: [copiedEntities[0].score, copiedEntities[0].score],
            year: [copiedEntities[0].entity.year, copiedEntities[0].entity.year],
          }
        );

        nodeLimits.value = limits.year;

        const worker = new EntitiesWorker<
          EntitiesWorkerNS.WorkerMessage,
          EntitiesWorkerNS.ClientMessage
        >();

        graph.value?.clear();

        worker.addEventListener('message', (event) => {
          switch (event.data.type) {
            case EntitiesWorkerNS.MessageType.FINISHED:
              worker.terminate();
              resolve();
              break;
            case EntitiesWorkerNS.MessageType.DATA:
              // Sometimes mergeNodes does not merge back in to graphology
              event.data.data.forEach((node) => graph.value?.mergeNode(node.key, node.attributes));
          }
        });

        // Send data
        worker.postMessage({
          action: EntitiesWorkerNS.WorkerAction.DATA,
          data: {
            limits,
            data: copiedEntities,
          },
        });
      });
    };

    const addEntitiesRefs = (entities: SearchResponse[]) => {
      return new Promise<void>((resolve) => {
        clusters.value = [];
        // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
        const copiedEntities = JSON.parse(JSON.stringify(entities));

        const worker = new EntitiesRefsWorker<
          EntitiesRefsWorkerNS.WorkerMessage,
          EntitiesRefsWorkerNS.ClientMessage
        >();

        graph.value?.clearEdges();

        worker.addEventListener('message', (event) => {
          switch (event.data.type) {
            case EntitiesRefsWorkerNS.MessageType.FINISHED: {
              worker.terminate();
              resolve();
              break;
            }
            case EntitiesRefsWorkerNS.MessageType.DATA: {
              const edgeSet = event.data.data.reduce((prev, e) => {
                if (graph.value?.hasNode(e.source) && graph.value?.hasNode(e.target)) {
                  prev.add({
                    ...e,
                    attributes: {
                      ...e.attributes,
                      important:
                        graph.value?.getNodeAttribute(e.source, 'citationCount') > 200 ||
                        graph.value?.getNodeAttribute(e.target, 'citationCount') > 200,
                    },
                  });
                }
                return prev;
              }, new Set<SerializedEdge<Attributes>>());
              edgeSet.forEach((edge) => {
                graph.value?.mergeEdge(edge.source, edge.target, edge.attributes);
              });
            }
          }
        });

        // Send data
        worker.postMessage({
          action: EntitiesRefsWorkerNS.WorkerAction.DATA,
          data: copiedEntities,
        });
      });
    };

    const addAnalysis = (analysis: AnalysisResponse[]) => {
      return new Promise<void>((resolve) => {
        if (analysis.length === 0) return resolve();

        /**
         // TODO: Why is this code at this position?
         */
        // remove single node clusters
        clusters.value = analysis[0].communities.filter((cluster) => cluster.properties.size > 1);

        // sort descending by cluster size
        clusters.value.sort((a, b) => b.properties.size - a.properties.size);

        // rename clusters: set idx as name
        for (let i = 0; i < clusters.value.length; i++) {
          clusters.value[i] = {
            id: clusters.value[i].id,
            displayName: clusters.value[i].displayName.split(' ')[0] + ' ' + (i + 1),
            properties: clusters.value[i].properties,
          };
        }
        // add special unassigned cluster at the end of the array
        clusters.value.push({
          id: '-1',
          displayName: 'Unassigned',
          properties: {
            // = amount of single node clusters
            size: analysis[0].communities.length - clusters.value.length,
            keywords: [],
          },
        });

        webGraph.value?.toggleNodeBackdropRendering(clusterColors.value, false);

        // convert the proxy object (returned by vuex and looks like a plain object :/ ) to a plain object for cloning to the worker
        const copiedAnalysis = JSON.parse(JSON.stringify(analysis[0])) as AnalysisResponse;

        graph.value?.clearEdges();

        const worker = new AnalysisWorker<
          AnalysisWorkerNS.WorkerMessage,
          AnalysisWorkerNS.ClientMessage
        >();

        worker.addEventListener('message', (event) => {
          switch (event.data.type) {
            case AnalysisWorkerNS.MessageType.FINISHED:
              worker.terminate();
              webGraph.value?.toggleNodeBackdropRendering(
                clusterColors.value,
                backdropActive.value
              );
              resolve();
              break;
            case AnalysisWorkerNS.MessageType.DATA:
              if (event.data.data.type === 'nodes') {
                const nodes = event.data.data.data.reduce((prev, { parsed, original }) => {
                  if (!graph.value?.hasNode(parsed.key)) {
                    parsed.attributes = {
                      ...parsed.attributes,
                      x: Math.random(),
                      y: Math.random(),
                      size: 4,
                      label: original.displayName,
                      type: original.type === 'author' ? NodeType.TRIANGLE : NodeType.CIRCLE,
                      color: '#c2c2c2',
                    };
                  }
                  return [...prev, parsed];
                }, [] as SerializedNode[]);
                nodes.forEach((node) => graph.value?.mergeNode(node.key, node.attributes));
              } else if (event.data.data.type === 'edges') {
                const edgeSet = event.data.data.data.reduce((prev, { parsed }) => {
                  if (graph.value?.hasNode(parsed.source) && graph.value.hasNode(parsed.target)) {
                    prev.add({
                      ...parsed,
                      attributes: {
                        ...parsed.attributes,
                        color: '#999999',
                        important:
                          graph.value?.getNodeAttribute(parsed.source, 'citationCount') > 200 ||
                          graph.value?.getNodeAttribute(parsed.target, 'citationCount') > 200,
                      },
                    });
                  }
                  return prev;
                }, new Set<SerializedEdge<Attributes>>());
                edgeSet.forEach((edge) =>
                  graph.value?.mergeEdge(edge.source, edge.target, edge.attributes)
                );
              }
          }
        });

        // Send data
        worker.postMessage({
          action: AnalysisWorkerNS.WorkerAction.DATA,
          data: {
            data: copiedAnalysis,
          },
        });
      });
    };

    const initGraph = (container: HTMLElement) => {
      graph.value = new UndirectedGraph();

      const sigmaSettings = {
        renderLabels: true,
        labelFontColor: '#8e8e8e',
        defaultEdgeType: 'line',
        renderJustImportantEdges: false,
      };

      const config = {
        highlightSubGraphOnHover: true,
        includeImportantNeighbors: false,
        importantNeighborsBidirectional: true,
        subGraphHighlightColor: '#ffc107',
        importantNeighborsColor: '#ff9800',
        defaultNodeType: NodeType.CIRCLE,
        labelSelector: LabelSelector.SIGMA,
        suppressContextMenu: true,
        showNodeInfoBoxOnClick: true,
        sigmaSettings: sigmaSettings,
      };

      const webGraph = new WebGraph(container, graph.value, config);

      webGraph.on('click', (e) => {
        store.dispatch('search/setSelectedEntityId', parseInt(e.node));
      });

      webGraph.render();

      webGraph.camera.animatedUnzoom(1.1);

      return webGraph;
    };

    const destroy = () => {
      webGraph.value?.destroy();
    };

    onUnmounted(destroy);

    const applyEdges = async () => {
      store.dispatch('loading/setLoading');
      if (currentAnalysis.value === PublicationAnalysisType.CITATIONNETWORK) {
        await addEntitiesRefs(entities.value);
      } else {
        if (analysis.value.length === 0) {
          store.dispatch('loading/unsetLoading');
          return;
        }

        if (currentAnalysis.value === AuthorAnalysisType.COAUTHORNETWORK) graph.value?.clear();

        await addAnalysis(analysis.value);
      }

      webGraph.value?.toggleEdgeRendering(
        Boolean(analysislayoutConfigurations[currentAnalysis.value].disableEdges)
      );

      await webGraph.value?.setAndApplyLayout(
        layoutConfigurations[currentLayout.value].layout,
        layoutConfigurations[currentLayout.value].options
      );
      store.dispatch('loading/unsetLoading');
    };

    watch([entities, analysis], async ([newEntities, newAnalysis], [oldEntities, oldAnalysis]) => {
      if (!webGraphContainer.value) return;
      store.dispatch('loading/setLoading');

      if (
        newEntities.length !== oldEntities.length ||
        (oldAnalysis[0]?.analysisType === AuthorAnalysisType.COAUTHORNETWORK &&
          newAnalysis[0]?.analysisType !== AuthorAnalysisType.COAUTHORNETWORK)
      ) {
        webGraph.value?.destroy();
        webGraph.value = null;
        graph.value?.clear();
        graph.value?.clearEdges();
        graph.value = null;

        if (newEntities.length === 0) {
          store.dispatch('loading/unsetLoading');
          return;
        }

        webGraph.value = initGraph(webGraphContainer.value) ?? null;

        await addEntities(newEntities);

        if (newEntities.length < 20) {
          store.dispatch('search/switchAnalysis', {
            type: PublicationAnalysisType.CITATIONNETWORK,
          });
        }
      }

      if (graph.value?.order === newEntities.length) {
        await applyEdges();
      }

      store.dispatch('loading/unsetLoading');
    });

    watch(
      () => selectedEntityId.value,
      (newId, oldId) => {
        if (oldId) {
          webGraph.value?.unhighlightNode(oldId);
        }
        if (newId) {
          webGraph.value?.highlightNode(newId);
        }
      }
    );

    const unmount = () => {
      webGraphContainer.value = null;
      contextMenuContainer.value = null;
      nodeInfoBoxContainer.value = null;
    };

    onBeforeUnmount(unmount);

    const manipulateView = (action: 'zoomIn' | 'reset' | 'zoomOut') => {
      switch (action) {
        case 'zoomIn':
          webGraph.value?.camera.animatedUnzoom(0.75);
          break;
        case 'reset':
          webGraph.value?.camera.animate({ ratio: 1.1, x: 0.5, y: 0.5 });
          break;
        case 'zoomOut':
          webGraph.value?.camera.animatedZoom(0.75);
          break;
      }
    };

    watch(
      () => currentLayout.value,
      async (newLayout) => {
        store.dispatch('loading/setLoading');
        await webGraph.value?.setAndApplyLayout(
          layoutConfigurations[newLayout].layout,
          layoutConfigurations[newLayout].options
        );
        store.dispatch('loading/unsetLoading');
      }
    );

    watch(
      () => backdropActive.value,
      (newValue) => {
        webGraph.value?.toggleNodeBackdropRendering(undefined, newValue);
        webGraph.value?.camera.animate({});
      }
    );

    watch(
      () => allEdges.value,
      (newValue) => {
        webGraph.value?.toggleJustImportantEdgeRendering(!newValue);
      }
    );

    watch(
      () => currentAnalysis.value,
      async (newAnalysis) => {
        if (analysislayoutConfigurations[newAnalysis].disableBackdrop && backdropActive.value) {
          webGraph.value?.toggleNodeBackdropRendering(undefined, false);
        }

        if (!analysislayoutConfigurations[newAnalysis].disableEdges && allEdges.value) {
          webGraph.value?.toggleJustImportantEdgeRendering(false);
        }
      }
    );

    /* ----------------------------------FILTER LOGIC---------------------------------- */
    const filterEntityIds = computed(() => store.getters['search/filterEntityIds'] as Set<number>);

    const textFilters: ComputedRef<Set<string>> = computed(
      () => store.getters['textFilter/filters']
    );

    const selectedClusterIds: ComputedRef<Set<number>> = computed(
      () => store.getters['clusterControls/selectedClusterIds'] as Set<number>
    );

    const applyTextFilter = (entities: SearchResponse[], textFilters: Set<string>): Set<number> => {
      if (textFilters.size > 0) {
        entities = entities.filter((result: SearchResponse) => {
          for (let filter of textFilters) {
            filter = filter.toLowerCase();
            // text
            if (
              result.entity?.abstract?.toLowerCase().includes(filter) ||
              result.entity?.title?.toLowerCase().includes(filter)
            ) {
              return true;
              // author name
            } else if (
              result.entity?.authors
                .map((author) => author.name.toLowerCase())
                .join(' ')
                .includes(filter)
            ) {
              return true;
              // publisher
            } else if (result.entity?.publisher?.toLowerCase() === filter) {
              return true;
              // year
            } else if (result.entity?.year === parseFloat(filter)) {
              return true;
            }
          }
          return false;
        });

        return new Set(entities.map((result) => result.entity?.id));
      } else {
        return new Set(entities.map((result) => result.entity?.id));
      }
    };

    const applyClusterFilter = (entityIds: Set<number>, selectedClusterIds: Set<number>) => {
      if (selectedClusterIds.size > 0) {
        return new Set(
          graph.value
            ?.nodes()
            .filter((nodeId) => {
              if (!entityIds.has(parseInt(nodeId))) return false;
              const nodeClusterId = parseInt(graph.value?.getNodeAttribute(nodeId, 'cluster'));
              return selectedClusterIds.has(nodeClusterId);
            })
            .map((nodeId) => parseInt(nodeId)) as number[]
        );
      } else {
        return entityIds;
      }
    };

    const applyFilters = () => {
      // filter by text
      let newFilteredEntityIds = applyTextFilter(entities.value, textFilters.value);

      // filter by selected clusters
      newFilteredEntityIds = applyClusterFilter(newFilteredEntityIds, selectedClusterIds.value);

      // update graph and result list
      store.dispatch('search/filterEntities', { ids: newFilteredEntityIds });
    };

    watch(textFilters, applyFilters, { deep: true });
    watch(selectedClusterIds, applyFilters, { deep: true });

    watch(filterEntityIds, () => {
      graph.value?.forEachNode((node) => {
        try {
          const nodeId = parseInt(node);
          const attributes = graph.value?.getNodeAttributes(node) || {};
          graph.value?.mergeNode(node, {
            ...attributes,
            hidden: !filterEntityIds.value.has(nodeId),
          });
        } catch (e) {
          console.error(e);
        }
      });
    });
    /* ----------------------------------FILTER LOGIC END---------------------------------- */

    const switchAnalysis = async (newAnalysis: AnalysisType) => {
      store.dispatch('loading/setLoading');
      await store.dispatch('search/switchAnalysis', { type: newAnalysis });
      store.dispatch('loading/unsetLoading');
    };

    // provide to all/deep child components
    provide('clusters', clusters);
    provide('clusterColors', clusterColors);

    return {
      clusters,
      clusterColors,
      nodeLimits,
      manipulateView,
      currentAnalysis,
      analysislayoutConfigurations,
      currentLayout,
      backdropActive,
      allEdges,
      webGraphContainer,
      contextMenuContainer,
      nodeInfoBoxContainer,
      switchAnalysis,
      selectedClusterIds,
    };
  },
});

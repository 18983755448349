
import { defineComponent, ref } from 'vue';
import { InformationCircleIcon, XIcon } from '@heroicons/vue/outline';

export default defineComponent({
  name: 'GraphHelp',
  components: {
    InformationCircleIcon,
    XIcon,
  },
  setup() {
    const showTooltip = ref<boolean>(false);
    return {
      showTooltip,
    };
  },
});

import { SortDirection } from '@/models/EntitySort';
import { Module } from 'vuex';
import { RootState } from '..';
import { SortBy } from '../../models/EntitySort';

export interface EntitySortState {
  sortBy: SortBy;
  sortDirection: SortDirection;
}

const SORT_BY_DEFAULT = SortBy.RELEVANCE;
const SORT_DIRECTION_DEFAULT = SortDirection.DESC;

export const entitySort: Module<EntitySortState, RootState> = {
  namespaced: true,
  state: { sortBy: SORT_BY_DEFAULT, sortDirection: SORT_DIRECTION_DEFAULT },
  getters: {
    sortBy: (state: EntitySortState) => {
      return state.sortBy;
    },
    sortByText: (state: EntitySortState) => {
      switch (state.sortBy) {
        case SortBy.RELEVANCE:
          return 'relevance';
        case SortBy.RECENCY:
          return 'recency';
        case SortBy.CITATION_COUNT:
          return 'citation count';
        case SortBy.ALPHABETIC:
          return 'title';
        default:
          return 'relevance';
      }
    },
    sortDirection: (state: EntitySortState) => {
      return state.sortDirection;
    },
  },
  mutations: {
    setSortBy: (state: EntitySortState, payload: SortBy) => {
      state.sortBy = payload;
    },
    setSortDirection: (state: EntitySortState, payload: SortDirection) => {
      state.sortDirection = payload;
    },
  },
  actions: {
    setSortBy: async ({ commit }, payload: SortBy) => {
      commit('setSortBy', payload);
    },
    resetSortBy: async ({ commit }) => {
      commit('setSortBy', SORT_BY_DEFAULT);
    },
    setSortDirection: async ({ commit }, payload: SortDirection) => {
      commit('setSortDirection', payload);
    },
    resetSortDirection: async ({ commit }) => {
      commit('setSortDirection', SORT_DIRECTION_DEFAULT);
    },
    reset: async ({ commit }) => {
      commit('setSortBy', SORT_BY_DEFAULT);
      commit('setSortDirection', SORT_DIRECTION_DEFAULT);
    },
  },
};

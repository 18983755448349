import { Module } from 'vuex';
import { RootState } from '..';

export interface TextFilterState {
  filters: Set<string>;
}

export const textFilter: Module<TextFilterState, RootState> = {
  namespaced: true,
  state: { filters: new Set<string>() },
  getters: {
    filters: (state: TextFilterState) => {
      return state.filters;
    },
  },
  mutations: {
    add: (state: TextFilterState, payload: string) => {
      state.filters.add(payload);
    },
    delete: (state: TextFilterState, payload: string) => {
      state.filters.delete(payload);
    },
    set: (state: TextFilterState, payload: Set<string>) => {
      state.filters = payload;
    },
    clear: (state: TextFilterState) => {
      state.filters.clear();
    },
  },
  actions: {
    add: async ({ commit }, payload: string) => {
      commit('add', payload);
    },
    delete: async ({ commit }, payload: string) => {
      commit('delete', payload);
    },
    set: async ({ commit }, payload: Set<string>) => {
      commit('set', payload);
    },
    clear: async ({ commit }) => {
      commit('clear');
    },
  },
};

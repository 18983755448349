
import { defineComponent, PropType } from 'vue';
import {
  ZoomInIcon,
  ZoomOutIcon,
  HomeIcon,
  PhotographIcon,
  SunIcon,
  CubeIcon,
  CubeTransparentIcon,
  CalculatorIcon,
} from '@heroicons/vue/outline';
import ControlButtonGroup from './ControlButtonGroup.vue';
import ControlMenuButton from './ControlMenuButton.vue';
import { AnalysisType, PublicationAnalysisType } from '@/models/Search';

interface ButtonOptions {
  disabled: boolean;
  active: boolean;
}

export default defineComponent({
  name: 'GraphControls',
  props: {
    currentLayout: String,
    currentAnalysis: String as PropType<AnalysisType>,
    backdrop: {
      type: Object as PropType<ButtonOptions>,
    },
    allEdges: {
      type: Object as PropType<ButtonOptions>,
    },
    disableAnalysis: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ControlButtonGroup,
    ControlMenuButton,
  },
  setup() {
    const viewControls = [
      {
        key: 'zoomIn',
        description: 'Zoom in',
        icon: ZoomInIcon,
      },
      {
        key: 'reset',
        description: 'Reset view',
        icon: HomeIcon,
      },
      {
        key: 'zoomOut',
        description: 'Zoom out',
        icon: ZoomOutIcon,
      },
    ];

    const layoutControls = [
      {
        key: 'forceAtlas2',
        description: 'Forceatlas2',
      },
      {
        key: 'fruchtermanReingold',
        description: 'Fruchterman-Reingold',
      },
      {
        key: 'circlepack',
        description: 'Circlepack',
      },
      {
        key: 'circular',
        description: 'Circular',
      },
      {
        key: 'random',
        description: 'Random',
      },
    ];

    const analysisControls = [
      {
        key: PublicationAnalysisType.HYBRIDFRONTS,
        description: 'Trending topics',
      },
      {
        key: PublicationAnalysisType.KNOWLEDGEBASES,
        description: 'Knowledge bases',
      },
      {
        key: PublicationAnalysisType.CITATIONNETWORK,
        description: 'Citation network',
      },
      /*{
        key: PublicationAnalysisType.RESEARCHFRONTS,
        description: 'Research fronts',
      },*/
      /*{
        key: AuthorAnalysisType.COAUTHORNETWORK,
        description: 'Author communities',
      },*/
    ];

    return {
      viewControls,
      layoutControls,
      analysisControls,
      PhotographIcon,
      SunIcon,
      CubeIcon,
      CubeTransparentIcon,
      CalculatorIcon,
    };
  },
});

import { Toast, ToastOptions, ToastTimeout } from '@/models/Toaster';
import { RootState } from '@/store';
import { Module } from 'vuex';

// State
export interface ToasterState {
  toasts: Array<Toast>;
}

enum ToasterAction {
  ADD_TOAST = 'ADD_TOAST',
  POP_TOAST = 'POP_TOAST',
}

export const toaster: Module<ToasterState, RootState> = {
  namespaced: true,
  state: {
    toasts: [],
  },
  getters: {
    toasts(state: ToasterState): Array<Toast> {
      return state.toasts;
    },
  },
  actions: {
    showToast({ commit }, options: ToastOptions) {
      const toast: Toast = {
        ...options,
        timestamp: Date.now(),
        timeout: ToastTimeout[options.severity],
      };
      commit(ToasterAction.ADD_TOAST, toast);
    },
    popToast({ commit }) {
      commit(ToasterAction.POP_TOAST);
    },
  },
  mutations: {
    [ToasterAction.ADD_TOAST](state: ToasterState, payload: Toast) {
      state.toasts.push(payload);
    },
    [ToasterAction.POP_TOAST](state: ToasterState) {
      state.toasts.shift();
    },
  },
};

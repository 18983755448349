export enum SortDirection {
  DESC,
  ASC,
}

export enum SortBy {
  RELEVANCE, // uses searchResponse.score returned by search API
  RECENCY, // uses searchResponse.entity.pubDate
  CITATION_COUNT,
  ALPHABETIC, // by title
}

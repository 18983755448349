import {
  AnalysisQuery,
  AnalysisResponse,
  AnalysisType,
  PublicationAnalysisType,
  SearchQuery,
  SearchResponse,
} from '@/models/Search/index';
import axios from 'axios';

export interface ResponseWrapper<T> {
  modelVersion?: number;
  time: number;
  results: T;
}

const core = axios.create({
  baseURL: process.env.VUE_APP_AMBA_CORE_API_URL || 'http://localhost:3000',
});

export const searchPublications = async (
  options: SearchQuery
): Promise<ResponseWrapper<SearchResponse[]>> => {
  const response = await core.get<ResponseWrapper<SearchResponse[]>>('/search/publications', {
    params: options,
  });
  return response.data;
};

const getAnalysisURLByAnalysisType = (type: AnalysisType) =>
  Object.values(PublicationAnalysisType).includes(type as PublicationAnalysisType)
    ? '/analysis/networks'
    : '/analysis/networks/authors';

export const analysis = async (
  options: AnalysisQuery<PublicationAnalysisType>
): Promise<ResponseWrapper<AnalysisResponse[]>> => {
  const response = await core.post<ResponseWrapper<AnalysisResponse[]>>(
    getAnalysisURLByAnalysisType(options.analysisType),
    {
      analysisRequest: options,
    }
  );

  return response.data;
};

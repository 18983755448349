import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import '@/assets/tailwind.css';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';
import Mouseflow from '@/plugins/Mouseflow';

// primevue UI component library
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'; //core css
import '@/assets/primevue-theme.css'; //theme
// do not use primevue icons, use tailwinds' icons instead (heroicons)
//import 'primeicons/primeicons.css'; //icons
// do not use primve grid system, use tailwinds' instead
//import 'primeflex/primeflex.css'; // grid system

const app = createApp(App);

// Check if mouseflow tracking key is present
if (process.env.VUE_APP_MOUSEFLOW_TRACKING_KEY) {
  app.use(Mouseflow, { tracking_key: process.env.VUE_APP_MOUSEFLOW_TRACKING_KEY });
}

// TODO move to @sentry/vue as soon as vue3 support is released
Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: 'dev-ambalytics-cloud@' + process.env.npm_package_version,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // tracingOrigins according to https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracingorigins
      tracingOrigins: ['api.ambalytics.com', 'api.ambalytics.cloud', /^\//],
    }),
  ],
  tracesSampleRate: 0.75,
});

app.config.errorHandler = (err, instance, info) => {
  Sentry.setTag('info', info);
  Sentry.captureException(err); // Manuelles Senden an Sentry
};

app.config.unwrapInjectedRef = true;

app.use(store).use(router).use(PrimeVue).mount('#app');

// TODO move to @sentry/vue as soon as vue3 support is released
window.addEventListener('error', (event) => {
  Sentry.captureException(event); // Manuelles Senden an Sentry
});
window.addEventListener('unhandledrejection', (event) => {
  Sentry.captureException(event); // Manuelles Senden an Sentry
});

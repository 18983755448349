
import { computed, ComputedRef, defineComponent } from 'vue';
import ProgressBar from 'primevue/progressbar';
import { useStore } from 'vuex';
import { APP_LOADER } from '@/store/modules/loading';

export default defineComponent({
  components: {
    ProgressBar,
  },
  setup() {
    const store = useStore();

    const isLoading: ComputedRef<boolean> = computed(() => {
      return store.getters['loading/isLoading'](APP_LOADER);
    });

    return {
      isLoading,
    };
  },
});

import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7714ad1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "tooltip tooltip-style"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["has-tooltip p-2 hover:bg-primary-light bg-primary-lightest rounded-lg shadow-md block w-full h-full backdrop-filter backdrop-blur-sm bg-opacity-50", {
      'bg-primary-lighter': _ctx.active && !_ctx.disabled,
      'hover:bg-primary-light': _ctx.active && !_ctx.disabled,
      disabled: _ctx.disabled,
    }]),
    disabled: _ctx.disabled
  }, [
    (_ctx.description && _ctx.showTooltip)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    (!_ctx.active && _ctx.inactiveIcon)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.inactiveIcon), { key: 1 }))
      : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { key: 2 })),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 10, _hoisted_1))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative w-full" }
const _hoisted_2 = {
  key: 0,
  class: "font-semibold cursor-default px-2"
}
const _hoisted_3 = {
  key: 1,
  class: "border-gray-500 mb-1 mx-1"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "h-4 w-4 inline-block mr-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Menu, null, {
      default: _withCtx(({ open }) => [
        _createVNode(_component_MenuButton, { as: "div" }, {
          default: _withCtx(() => [
            _createVNode(_component_ControlButton, {
              showTooltip: !open,
              description: _ctx.menuTitle,
              icon: _ctx.menuIcon,
              disabled: _ctx.disabled
            }, null, 8, ["showTooltip", "description", "icon", "disabled"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_MenuItems, { class: "absolute right-9 top-0 py-2 px-1 rounded-lg bg-primary-lightest shadow-md text-sm bg-opacity-80 backdrop-filter backdrop-blur-sm whitespace-nowrap" }, {
          default: _withCtx(() => [
            (_ctx.menuTitle)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.menuTitle), 1))
              : _createCommentVNode("", true),
            (_ctx.menuTitle)
              ? (_openBlock(), _createElementBlock("hr", _hoisted_3))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_MenuItem, {
                key: item.key
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.$emit('menuCick', item.key)),
                    class: _normalizeClass(["py-1 px-2 font-semibold w-full text-left rounded-lg flex items-center border hover:bg-primary-light border-transparent", {
              'bg-yellow-200': _ctx.selectedIndex === index,
            }])
                  }, [
                    (_ctx.hasAtleastOneItemWithIcon)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          (item.icon)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { key: 0 }))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", null, _toDisplayString(item.description), 1)
                  ], 10, _hoisted_4)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
<template>
  <div class="text-xs font-light text-gray-800">
    <a href="https://ambalytics.com/privacy-policy" target="_blank">Privacy Policy</a> |
    <a href="https://ambalytics.com/imprint" target="_blank">Imprint</a>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>


import { defineComponent } from 'vue';
import { ExternalLinkIcon } from '@heroicons/vue/solid';

export default defineComponent({
  name: 'OpenDoi',
  components: { ExternalLinkIcon },
  props: {
    doi: String,
    text: String,
  },
  setup(props) {
    const openDoi = () => {
      var href = 'https://doi.org/';
      href += props.doi;
      window.open(href);
    };
    return { openDoi };
  },
});

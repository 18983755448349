
import { computed, defineComponent, PropType, VNode } from 'vue';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
import ControlButton from '@/components/Graphs/GraphControls/ControlButton.vue';

interface Item {
  key: string;
  description: string;
  icon?: VNode;
}

export default defineComponent({
  name: 'ControlMenuButton',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ControlButton,
  },
  props: {
    menuTitle: {
      type: String,
    },
    menuIcon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: true,
    },
    menuItems: {
      type: Array as PropType<Array<Item>>,
      required: true,
    },
    selected: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const hasAtleastOneItemWithIcon = computed(() => props.menuItems.some((item) => item.icon));
    const selectedIndex = computed(() =>
      props.menuItems.findIndex((item) => item.key === props.selected)
    );

    return {
      selectedIndex,
      hasAtleastOneItemWithIcon,
    };
  },
});


import ExampleTile from '@/components/Home/ExampleTile.vue';
import PrivacyPolicyImprintLinks from '@/components/legal/PrivacyPolicyImprintLinks.vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';
import SearchBar from '@/components/Search/SearchBar.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    SearchBar,
    StandardLogo,
    PrivacyPolicyImprintLinks,
    ExampleTile,
  },
  setup() {
    const examples = [
      {
        title: 'Solar Energy',
        searchQuery: 'solar energy',
        imgSrc: require('@/assets/images/examples/solarenergy.png'),
      },
      {
        title: 'Coronavirus',
        searchQuery: 'coronavirus',
        imgSrc: require('@/assets/images/examples/coronavirus.png'),
      },
      {
        title: 'Autonomous Vehicle',
        searchQuery: 'autonomous vehicle',
        imgSrc: require('@/assets/images/examples/autonomousvehicle.png'),
      },
      {
        title: 'Microsoft',
        searchQuery: 'Microsoft',
        imgSrc: require('@/assets/images/examples/microsoft.png'),
      },
      {
        title: 'Christian Drosten',
        searchQuery: 'Christian Drosten',
        imgSrc: require('@/assets/images/examples/christiandrosten.png'),
      },
    ];
    return { examples };
  },
});

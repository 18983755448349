
import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { Toast, ToastSeverity } from '@/models/Toaster';
import {
  ExclamationIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/solid';

export default defineComponent({
  name: 'Toast',
  props: {
    toast: Object as PropType<Toast>,
  },
  components: {
    ExclamationIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    CheckCircleIcon,
  },
  setup(props) {
    const store = useStore();
    const toast = ref();

    const color = computed(() => {
      switch (toast.value.severity) {
        case ToastSeverity.SUCCESS:
          return 'green';
        case ToastSeverity.WARNING:
          return 'yellow';
        case ToastSeverity.ERROR:
          return 'red';
        default:
          return 'blue';
      }
    });

    const removeToast = () => {
      if (toast.value.timeout) {
        setTimeout(() => {
          store.dispatch('toaster/popToast', toast.value.timestamp);
        }, toast.value.timeout);
      }
    };

    onMounted(() => {
      toast.value = props.toast;
      removeToast();
    });

    return { removeToast, color };
  },
});

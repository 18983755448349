
import { defineComponent, PropType, VNode } from 'vue';

export default defineComponent({
  name: 'ControlButton',
  props: {
    description: String,
    showTooltip: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: true,
    },
    inactiveIcon: {
      // ? Unfortunately the only type annotation that works for VNodes
      type: Function as PropType<() => VNode>,
      required: false,
    },
  },
});

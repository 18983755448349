import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container fixed bottom-4 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToastComponent = _resolveComponent("ToastComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toasts, (toast) => {
      return (_openBlock(), _createBlock(_component_ToastComponent, {
        toast: toast,
        key: toast.timestamp
      }, null, 8, ["toast"]))
    }), 128))
  ]))
}
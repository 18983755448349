import { Module } from 'vuex';
import { RootState } from '..';

export interface ClusterControlState {
  selectedClusterIds: Set<number>;
}

export const clusterControls: Module<ClusterControlState, RootState> = {
  namespaced: true,
  state: { selectedClusterIds: new Set<number>() },
  getters: {
    selectedClusterIds: (state: ClusterControlState) => {
      return state.selectedClusterIds;
    },
  },
  mutations: {
    add: (state: ClusterControlState, payload: number) => {
      state.selectedClusterIds.add(payload);
    },
    delete: (state: ClusterControlState, payload: number) => {
      state.selectedClusterIds.delete(payload);
    },
    set: (state: ClusterControlState, payload: Set<number>) => {
      state.selectedClusterIds = payload;
    },
    clear: (state: ClusterControlState) => {
      state.selectedClusterIds.clear();
    },
  },
  actions: {
    add: async ({ commit }, payload: number) => {
      commit('add', payload);
    },
    delete: async ({ commit }, payload: number) => {
      commit('delete', payload);
    },
    set: async ({ commit }, payload: Set<number>) => {
      commit('set', payload);
    },
    clear: async ({ commit }) => {
      commit('clear');
    },
  },
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-4 right-4 w-8 text-gray-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButtonGroup = _resolveComponent("ControlButtonGroup")!
  const _component_ControlMenuButton = _resolveComponent("ControlMenuButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ControlButtonGroup, {
        controls: _ctx.viewControls,
        onClick: _cache[0] || (_cache[0] = (e) => _ctx.$emit('manipulateView', e))
      }, null, 8, ["controls"]),
      _createVNode(_component_ControlMenuButton, {
        class: "mt-2",
        disabled: _ctx.disableAnalysis,
        menuTitle: `Select analysis type${
          _ctx.disableAnalysis ? ' \n Please choose a graph with at least 20 elements' : ''
        }`,
        menuIcon: _ctx.CalculatorIcon,
        menuItems: _ctx.analysisControls,
        selected: _ctx.currentAnalysis,
        onMenuCick: _cache[1] || (_cache[1] = (e) => _ctx.$emit('changeAnalysis', e))
      }, null, 8, ["disabled", "menuTitle", "menuIcon", "menuItems", "selected"])
    ])
  ]))
}
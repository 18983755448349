
import MobileLogo from '@/components/Logos/MobileLogo.vue';
import StandardLogo from '@/components/Logos/StandardLogo.vue';
import SearchBar from '@/components/Search/SearchBar.vue';
import { defineComponent } from 'vue';
import LoadingIndicator from './LoadingIndicator.vue';
export default defineComponent({
  name: 'AppBar',
  components: {
    SearchBar,
    StandardLogo,
    MobileLogo,
    LoadingIndicator,
  },
  setup() {
    return {};
  },
});

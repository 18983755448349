<template>
  <button class="rounded px-3 text-center border border-gray-300 h-10 hover:bg-gray-100">
    <div class="h-4 w-4">
      <slot></slot>
    </div>
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss"></style>

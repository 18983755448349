
import { defineComponent, PropType, VNode } from 'vue';
import ControlButton from '@/components/Graphs/GraphControls/ControlButton.vue';

interface Control {
  key: string;
  description: string;
  icon: VNode;
}

export default defineComponent({
  name: 'ControlButtonGroup',
  components: {
    ControlButton,
  },
  props: {
    controls: {
      type: Array as PropType<Array<Control>>,
      required: true,
    },
  },
});

import { App } from 'vue';

declare global {
  interface Window {
    _mfq: unknown[];
  }
}
// // 1. Make sure to import 'vue' before declaring augmented types
// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// import Vue from 'vue';

// // 2. Specify a file with the types you want to augment
// //    Vue has the constructor type in types/vue.d.ts
// declare module 'vue/types/vue' {
//   // 3. Declare augmentation for Vue
//   interface Vue {
//     $mf: {
//       push: push;
//       logRouteChange: logRouteChange;
//     };
//   }
// }

export default {
  install: (app: App, { tracking_key }: { tracking_key?: string }): void => {
    app.config.globalProperties.$mf = {
      push: (commandName: string, ...args: string[]) => window._mfq.push([commandName, ...args]),
    };

    if (tracking_key === undefined) {
      throw new Error('No Mouseflow tracking key specified.');
    }

    window._mfq = window._mfq || [];

    const mf = document.createElement('script');
    mf.type = 'text/javascript';
    mf.async = true;
    mf.src = '//cdn.mouseflow.com/projects/' + tracking_key + '.js';

    document.getElementsByTagName('head')[0].appendChild(mf);
  },
};

export enum ToastSeverity {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export interface ToastOptions {
  severity: ToastSeverity;
  message: string;
}

export interface Toast extends ToastOptions {
  timestamp: number;
  timeout: number;
}

export const ToastTimeout: { [x in ToastSeverity]: number } = {
  SUCCESS: 3000,
  INFO: 4000,
  WARNING: 5000,
  ERROR: 30000,
};

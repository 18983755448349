import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-16 border-b border-gray-300" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "absolute" }
const _hoisted_4 = { class: "absolute pl-4 h-16 flex flex-col justify-center invisible md:visible" }
const _hoisted_5 = { class: "absolute pl-4 h-16 flex flex-col justify-center visible md:invisible" }
const _hoisted_6 = { class: "flex justify-end sm:justify-center pr-4 md:pr-0" }
const _hoisted_7 = { class: "h-16 flex flex-col justify-center w-2/3 md:w-1/2 xl:w-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_standard_logo = _resolveComponent("standard-logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mobile_logo = _resolveComponent("mobile-logo")!
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_loading_indicator = _resolveComponent("loading-indicator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: { name: 'Home' },
              class: "text-2xl inline-block float-left m-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_standard_logo)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: { name: 'Home' },
              class: "text-2xl inline-block float-left m-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_mobile_logo)
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_SearchBar)
        ])
      ])
    ]),
    _createVNode(_component_loading_indicator)
  ], 64))
}
//import { SearchState } from './search';
import { ToastSeverity } from '@/models/Toaster/index';
import { core } from '@/api';
import {
  AnalysisParameters,
  AnalysisResponse,
  AnalysisType,
  AuthorAnalysisType,
  PublicationAnalysisType,
  SearchResponse,
} from '@/models/Search';
import { RootState } from '@/store';
import { Module } from 'vuex';

// State
export interface SearchState {
  entities: SearchResponse[];
  currentAnalysis: AnalysisType;
  analysis: { [key in AnalysisType]: AnalysisResponse[] };
  selectedEntityId: number | undefined;
  hoverEntityIds: number[];
  filterEntityIds: Set<number>;
  error?: string;
}

enum SearchAction {
  FETCH_SEARCH = 'FETCH_SEARCH',
  RESET_SEARCH = 'RESET_SEARCH',
  SET_ERROR = 'SET_ERROR',
  SET_SELECTED_ENTITY_ID = 'SET_SELECTED_ENTITY_ID',
  SET_HOVER_ENTITY_IDS = 'SET_HOVER_ENTITY_IDS',
  SET_FILTER_ENTITY_IDS = 'SET_FILTER_ENTITY_IDS',
  FETCH_ANALYSIS = 'FETCH_ANALYSIS',
  RESET_ANALYSIS = 'RESET_ANALYSIS',
  SWITCH_ANALYSIS = 'SWITCH_ANALYSIS',
}

export const search: Module<SearchState, RootState> = {
  namespaced: true,
  state: {
    entities: [],
    currentAnalysis: PublicationAnalysisType.HYBRIDFRONTS,
    analysis: {
      [PublicationAnalysisType.CITATIONNETWORK]: [],
      [PublicationAnalysisType.HYBRIDFRONTS]: [],
      [PublicationAnalysisType.KNOWLEDGEBASES]: [],
      [PublicationAnalysisType.RESEARCHFRONTS]: [],
      [AuthorAnalysisType.COAUTHORNETWORK]: [],
    },
    selectedEntityId: undefined,
    hoverEntityIds: [],
    filterEntityIds: new Set<number>(),
    error: undefined,
  },
  getters: {
    entities(state: SearchState): SearchResponse[] {
      return state.entities;
    },
    currentAnalysis(state: SearchState): AnalysisType {
      return state.currentAnalysis;
    },
    currentNodeType(state: SearchState): string {
      return Object.values(PublicationAnalysisType).includes(
        state.currentAnalysis as PublicationAnalysisType
      )
        ? 'publications'
        : 'authors';
    },
    analysis:
      (state: SearchState) =>
      (analysisType?: AnalysisType): AnalysisResponse[] => {
        return analysisType ? state.analysis[analysisType] : state.analysis[state.currentAnalysis];
      },
    selectedEntityId(state: SearchState): number | undefined {
      return state.selectedEntityId;
    },
    hoverEntityIds(state: SearchState): number[] {
      return state.hoverEntityIds;
    },
    filterEntityIds(state: SearchState): Set<number> {
      return state.filterEntityIds;
    },
    titles(state: SearchState): Set<string> {
      return new Set(state.entities.map((entity) => entity.entity.title));
    },
    years(state: SearchState): Set<number> {
      return new Set(state.entities.map((entity) => entity.entity.year));
    },
    authorNames(state: SearchState): Set<string> {
      return new Set(
        state.entities.flatMap((entity) => entity.entity.authors).map((author) => author.name)
      );
    },
    publishers(state: SearchState): Set<string> {
      return new Set(state.entities.map((entity) => entity.entity.publisher));
    },
  },
  actions: {
    async fetch({ dispatch, commit, state }, { query }: { query: string }) {
      commit(SearchAction.RESET_SEARCH);
      commit(SearchAction.RESET_ANALYSIS);
      // clear text filter
      await dispatch('textFilter/clear', undefined, { root: true });
      // reset list sorting
      await dispatch('entitySort/reset', undefined, { root: true });

      try {
        const entities = await core.searchPublications({
          q: query,
          count: 500,
        });

        commit(SearchAction.FETCH_SEARCH, entities.results);

        dispatch('fetchAnalysis', { type: state.currentAnalysis });
      } catch (e) {
        commit(SearchAction.SET_ERROR, 'Error while fetching result!');
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
      }
    },
    async fetchAnalysis(
      { dispatch, commit, state },
      { type, force }: { type: AnalysisType; force: boolean }
    ) {
      try {
        const params: AnalysisParameters = { detectCommunities: true };

        switch (type) {
          case PublicationAnalysisType.HYBRIDFRONTS:
            params.hybridSimilarityRatio = 0.6;
            params.maxEdges = 10;
            break;
          // case AuthorAnalysisType.COAUTHORNETWORK:
          //   params.expandGraph = false;
          //   break;
        }

        // We don't want to fetch citation networks from the server as parsing the refs in the FE is quicker
        // Also only re-fetch analysis if forced or empty (not loaded)
        if (
          type !== PublicationAnalysisType.CITATIONNETWORK &&
          (force || state.analysis[type].length === 0)
        ) {
          if (state.entities.length > 20) {
            const analysis = await core.analysis({
              analysisType: type as PublicationAnalysisType,
              analysisParameters: params,
              entityIds: state.entities.map((entity) => entity.entity.id),
            });

            commit(SearchAction.FETCH_ANALYSIS, { type, results: analysis.results });
          } else {
            commit(SearchAction.FETCH_ANALYSIS, { type, results: [] });
          }
        }
      } catch (e) {
        commit(SearchAction.SET_ERROR, 'Error while fetching result!');
        dispatch(
          'toaster/showToast',
          {
            severity: ToastSeverity.ERROR,
            message: 'Error while fetching result!',
          },
          { root: true }
        );
      }
    },
    setSelectedEntityId({ commit }, id: number) {
      commit(SearchAction.SET_SELECTED_ENTITY_ID, id);
    },
    filterEntities({ commit }, { ids }: { ids: Set<number> }) {
      commit(SearchAction.SET_FILTER_ENTITY_IDS, ids);
    },
    async switchAnalysis({ dispatch, commit, state }, { type }: { type: AnalysisType }) {
      const alreadyFetched = state.analysis[type].length > 0;

      if (!alreadyFetched) {
        await dispatch('fetchAnalysis', { type });
      }

      commit(SearchAction.SWITCH_ANALYSIS, type);
    },
  },
  mutations: {
    [SearchAction.FETCH_SEARCH](state: SearchState, payload: SearchResponse[]) {
      state.error = undefined;
      state.entities = payload;
      state.filterEntityIds = new Set(state.entities.map((result) => result.entity.id));
    },
    [SearchAction.RESET_SEARCH](state: SearchState) {
      state.error = undefined;
      state.entities = [];
      state.filterEntityIds = new Set<number>();
      state.selectedEntityId = undefined;
      state.hoverEntityIds = [];
    },
    [SearchAction.FETCH_ANALYSIS](
      state: SearchState,
      payload: { type: AnalysisType; results: AnalysisResponse[] }
    ) {
      state.error = undefined;
      state.analysis[payload.type] = payload.results;
    },
    [SearchAction.RESET_ANALYSIS](state: SearchState, payload?: { types?: AnalysisType[] }) {
      state.error = undefined;
      const emptyAnalysis = {
        [PublicationAnalysisType.CITATIONNETWORK]: [],
        [PublicationAnalysisType.HYBRIDFRONTS]: [],
        [PublicationAnalysisType.KNOWLEDGEBASES]: [],
        [PublicationAnalysisType.RESEARCHFRONTS]: [],
        [AuthorAnalysisType.COAUTHORNETWORK]: [],
      };
      if (!payload?.types) {
        state.analysis = emptyAnalysis;
      } else {
        payload.types.forEach((t) => (state.analysis[t] = []));
      }
    },
    [SearchAction.SET_ERROR](state: SearchState, payload: string) {
      state.entities = [];
      state.error = payload;
    },
    [SearchAction.SET_SELECTED_ENTITY_ID](state: SearchState, id: number) {
      state.selectedEntityId = id;
    },
    [SearchAction.SET_HOVER_ENTITY_IDS](state: SearchState, ids: number[]) {
      state.hoverEntityIds = ids;
    },
    [SearchAction.SET_FILTER_ENTITY_IDS](state: SearchState, ids: Set<number>) {
      state.filterEntityIds = ids;
    },
    [SearchAction.SWITCH_ANALYSIS](state: SearchState, type: AnalysisType) {
      state.currentAnalysis = type;
    },
  },
};

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "border border-gray-300 rounded hover:border-gray-600" }
const _hoisted_2 = { class: "flex justify-center p-2" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-md border-t border-gray-300 p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: 'Search', query: { q: _ctx.searchQuery } }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.imgSrc,
            class: "w-32"
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
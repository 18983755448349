import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute top-1/2 bottom-1/2 h-8 w-8 inline-block my-auto left-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_ExclamationIcon = _resolveComponent("ExclamationIcon")!
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative rounded-lg border-l-4 inline-block items-center pr-4 pl-2 py-2 max-w-2xl", [`bg-${_ctx.color}-200`, `border-${_ctx.color}-500`]])
  }, [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.toast.severity === 'SUCCESS')
        ? (_openBlock(), _createBlock(_component_CheckCircleIcon, {
            key: 0,
            class: "text-green-500"
          }))
        : (_ctx.toast.severity === 'WARNING')
          ? (_openBlock(), _createBlock(_component_ExclamationIcon, {
              key: 1,
              class: "text-yellow-500"
            }))
          : (_ctx.toast.severity === 'ERROR')
            ? (_openBlock(), _createBlock(_component_ExclamationCircleIcon, {
                key: 2,
                class: "text-red-500"
              }))
            : (_openBlock(), _createBlock(_component_InformationCircleIcon, {
                key: 3,
                class: "text-blue-500"
              }))
    ]),
    _createElementVNode("p", {
      class: _normalizeClass(["pl-10 text-left", [`text-${_ctx.color}-500`]])
    }, _toDisplayString(_ctx.toast.message), 3)
  ], 2))
}

import { defineComponent } from 'vue';
import ToastsContainer from '@/components/Toaster/ToastsContainer.vue';

export default defineComponent({
  name: 'App',
  components: {
    ToastsContainer,
  },
});


import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: String,
    searchQuery: String,
    imgSrc: String,
  },
  setup() {
    return {};
  },
});

import { createStore } from 'vuex';
import { clusterControls, ClusterControlState } from './modules/clusterControls';
import { loading, LoadingState } from './modules/loading';
import { search, SearchState } from './modules/search';
import { textFilter, TextFilterState } from './modules/textFilters';
import { entitySort, EntitySortState } from './modules/entitySort';
import { toaster, ToasterState } from './modules/toaster';

export interface RootState {
  toaster?: ToasterState;
  search?: SearchState;
  loading?: LoadingState;
  clusterControls?: ClusterControlState;
  textFilter?: TextFilterState;
  entitySort?: EntitySortState;
}

const isProduction = process.env.NODE_ENV === 'production';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    toaster: toaster,
    search: search,
    loading: loading,
    clusterControls: clusterControls,
    textFilter: textFilter,
    entitySort: entitySort,
  },
  strict: !isProduction,
  devtools: !isProduction,
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full flex" }
const _hoisted_2 = { class: "w-1/3 h-full overflow-y-auto" }
const _hoisted_3 = { class: "w-2/3 h-full" }
const _hoisted_4 = { class: "w-full h-full" }
const _hoisted_5 = { class: "w-full h-full relative" }
const _hoisted_6 = {
  ref: "webGraphContainer",
  class: "w-full h-full"
}
const _hoisted_7 = { class: "absolute top-2 right-2" }
const _hoisted_8 = { class: "absolute left-2 bottom-2" }
const _hoisted_9 = { class: "absolute right-2 bottom-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftPane = _resolveComponent("LeftPane")!
  const _component_GraphControls = _resolveComponent("GraphControls")!
  const _component_PrivacyPolicyImprintLinks = _resolveComponent("PrivacyPolicyImprintLinks")!
  const _component_GraphHelp = _resolveComponent("GraphHelp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LeftPane, {
        oldestYear: _ctx.nodeLimits[0],
        newestYear: _ctx.nodeLimits[1],
        clusters: _ctx.clusters,
        clusterColors: _ctx.clusterColors,
        currentAnalysis: _ctx.currentAnalysis
      }, null, 8, ["oldestYear", "newestYear", "clusters", "clusterColors", "currentAnalysis"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, null, 512),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_GraphControls, {
              onManipulateView: _ctx.manipulateView,
              onChangeAnalysis: _ctx.switchAnalysis,
              onChangeLayout: _cache[0] || (_cache[0] = (newLayout) => (_ctx.currentLayout = newLayout)),
              currentLayout: _ctx.currentLayout,
              disableAnalysis: _ctx.entities.length < 20,
              currentAnalysis: _ctx.currentAnalysis,
              backdrop: {
                active: _ctx.backdropActive,
                disabled: _ctx.analysislayoutConfigurations[_ctx.currentAnalysis].disableBackdrop,
              },
              onToggleBackdrop: _cache[1] || (_cache[1] = ($event: any) => (_ctx.backdropActive = !_ctx.backdropActive)),
              allEdges: {
                active: _ctx.allEdges,
                disabled: _ctx.analysislayoutConfigurations[_ctx.currentAnalysis].disableEdges,
              },
              onToggleAllEdges: _cache[2] || (_cache[2] = ($event: any) => (_ctx.allEdges = !_ctx.allEdges))
            }, null, 8, ["onManipulateView", "onChangeAnalysis", "currentLayout", "disableAnalysis", "currentAnalysis", "backdrop", "allEdges"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_PrivacyPolicyImprintLinks, { class: "inline" })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_GraphHelp)
          ])
        ])
      ])
    ])
  ]))
}
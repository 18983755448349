import { RootState } from '@/store';
import { Module } from 'vuex';

/**
 * Key (string), value (number) map
 * with capability to in-/decrement numbers
 */
class LoaderMap extends Map<string, number> {
  constructor() {
    super();
  }

  increment(key: string): void {
    const val = this.get(key);
    if (val !== undefined && val < Number.MAX_SAFE_INTEGER) this.set(key, val + 1);
  }

  decrement(key: string): void {
    const val = this.get(key);
    if (val !== undefined && val > 0) this.set(key, val - 1);
  }
}

export interface LoadingState {
  loaders: LoaderMap;
}

/**
 * The application wide loader, LoadingIndicator in AppBar uses this
 */
export const APP_LOADER = 'global';

/**
 * The Loading module provides an API to define loaders
 * To use the application wide loader, no loader has to be specified in methods
 * To register a local loader, pass name of local loader as "loader" param
 * The state can be utilized to indicate application loading to the user
 */
export const loading: Module<LoadingState, RootState> = {
  namespaced: true,
  state: {
    // init loaders with APP_LOADER
    loaders: new LoaderMap().set(APP_LOADER, 0),
  },
  getters: {
    isLoading:
      (state: LoadingState) =>
      (loader = APP_LOADER): boolean | undefined => {
        const val = state.loaders.get(loader);
        if (!val) return undefined;
        if (val > 0) return true;
        else return false;
      },
  },
  mutations: {
    register(state: LoadingState, loader: string): void {
      state.loaders.set(loader, 0);
    },
    deregister(state: LoadingState, loader: string): boolean {
      return state.loaders.delete(loader);
    },
    increment(state: LoadingState, loader: string): void {
      state.loaders.increment(loader);
    },
    decrement(state: LoadingState, loader: string): void {
      state.loaders.decrement(loader);
    },
  },
  actions: {
    /**
     * Sets a loader to loading state (increments counter)
     *
     * @param loader The loader to set to loading
     */
    setLoading({ commit, state }, loader = APP_LOADER) {
      if (!state.loaders.has(loader)) commit('register', loader);
      commit('increment', loader);
    },
    /**
     * Decrements counter of loader
     *
     * @param loader The loader to mutate
     */
    unsetLoading({ commit }, loader = APP_LOADER) {
      commit('decrement', loader);
    },
    /**
     * Deregisters a loader, clears up memory
     *
     * @param loader The loader to delete
     */
    deregister({ commit }, loader: string) {
      commit('deregister', loader);
    },
  },
};


import { defineComponent } from 'vue';
import AppBar from '@/components/Skeleton/AppBar.vue';

export default defineComponent({
  name: 'AppSkeleton',
  components: {
    AppBar,
  },
  props: {},
  setup() {
    return {};
  },
});

export interface SearchQuery {
  q: string;
  count?: number;
  offset?: number;
  rfields?: string;
  orderby?: string;
  options?: string;
}

export interface SearchResponse {
  score: number;
  entity: {
    id: number;
    type: string; // TODO this should be an enum
    doi: string;
    pubDate: string;
    publisher: string;
    citationCount: number;
    title: string;
    normalizedTitle: string;
    year: number;
    refs: number[];
    authors: {
      id: number;
      name: string;
      affiliation: {
        id: number;
        name: string;
      };
    }[];
    abstract: string;
  };
}

export enum GraphDrawingLayout {
  FORCEATLAS2 = 'forceAtlas2',
  FRUCHTERMANREINGOLD = 'fruchtermanReingold',
  CIRCLEPACK = 'circlepack',
  CIRCULAR = 'circular',
  RANDOM = 'random',
}

export enum PublicationAnalysisType {
  CITATIONNETWORK = 'citationNetwork',
  KNOWLEDGEBASES = 'knowledgeBases',
  RESEARCHFRONTS = 'researchFronts',
  HYBRIDFRONTS = 'hybridFronts',
}

export enum AuthorAnalysisType {
  COAUTHORNETWORK = 'coAuthorNetwork',
}

export type AnalysisType = PublicationAnalysisType | AuthorAnalysisType;

export interface AnalysisParameters {
  expandGraph?: boolean;
  weightGraph?: boolean;
  removeIsolatedNodes?: boolean;
  detectCommunities?: boolean;
  communityDetectionType?: 'lexical' | 'graph'; // TODO: Check for other possibilities
  hybridSimilarityRatio?: number;
  maxEdges?: number;
}

export interface AnalysisQuery<T extends AnalysisType> {
  analysisType: T;
  analysisParameters?: AnalysisParameters;
  entityIds: number[];
}

export interface AnalysisResponseEntity {
  id: string;
  type: string;
  displayName: string;
  docType: string;
  doi: string;
  citationCount: string;
  properties: {
    communityId: number;
  };
}

export interface AnalysisResponseLink {
  sourceId: number;
  targetId: number;
  properties: {
    weight: number;
  };
}

export interface Community {
  id: string;
  displayName: string;
  properties: {
    keywords: [string, number][];
    size: number;
  };
}

export interface AnalysisResponse {
  analysisId: string;
  analysisType: string;
  entities: AnalysisResponseEntity[];
  links: AnalysisResponseLink[];
  communities: Community[];
}

// TODO: to be deleted
export interface ClusterMapping {
  [id: number]: { name: string; keywords: string[]; size: number };
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg shadow-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ControlButton = _resolveComponent("ControlButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.controls, (control, index) => {
      return (_openBlock(), _createBlock(_component_ControlButton, {
        class: _normalizeClass(["shadow-none", { 'rounded-t-none': index !== 0, 'rounded-b-none': index !== _ctx.controls.length - 1 }]),
        key: control.key,
        description: control.description,
        onClick: ($event: any) => (_ctx.$emit('click', control.key)),
        icon: control.icon
      }, null, 8, ["class", "description", "onClick", "icon"]))
    }), 128))
  ]))
}
import { SearchResponse } from '@/models/Search';
import { SerializedNode } from 'graphology-types';
import { interpolateBlues } from 'd3-scale-chromatic';
import WebpackWorker from 'worker-loader!*';

// *-------------------------------------------------------------------------------------
// *                       COPY OF THE WEBGRAPH UTILS
// *-------------------------------------------------------------------------------------
// ! Sigma.js unfortunately calls getPixelRatio on import... This requires the window object
// ! And webgraph imports sigma.js on import.

function getNormalizedValue(value: number, minValue: number, maxValue: number): number {
  return (value - minValue) / (maxValue - minValue);
}

/**
 * This function returns the size of a node for a given value.
 *
 * @param {number} value - The value of the node as a number
 * @param {number} minValue - The minimum value of any node in the node-set this function is applied to
 * @param {number} maxValue - The maximum value of any node in the node-set this function is applied to
 * @param {number} [steps=3] - The amount of different steps of node sizes, must be positive
 * @param {number} [minNodeSize=6] - The minimum node size in Pixel
 * @param {number} [maxNodeSize=12] - The maximum node size in Pixel
 * @returns {number}
 */
const getNodeSizeForValue = (
  value: number,
  minValue: number,
  maxValue: number,
  steps = 3,
  minNodeSize = 6,
  maxNodeSize = 12
): number => {
  if (steps <= 0 || minNodeSize <= 0 || maxNodeSize <= 0) {
    throw new Error(
      'steps, minNodeSize and maxNodeSize must all be positive numbers and greater 0'
    );
  }

  if (minNodeSize > maxNodeSize) {
    throw new Error("minNodeSize can't be larger than maxNodeSize");
  }

  let divider = steps;
  if (divider != 1) {
    divider -= 1;
  }

  const sizeOffset = (maxNodeSize - minNodeSize) / divider;

  const interval = Math.abs(maxValue - minValue) / steps;

  let section = Math.floor((value - minValue) / interval);
  section = value === maxValue ? section - 1 : section;

  return minNodeSize + section * sizeOffset;
};

// *                                    END
// *-------------------------------------------------------------------------------------

// *-------------------------------------------------------------------------------------
// *                  MODELS/INTERFACES TO USE IN CLIENT AND WORKER
// *-------------------------------------------------------------------------------------

export enum WorkerAction {
  DATA = 'DATA',
}

export interface ClientDataMessage {
  action: WorkerAction.DATA;
  data: {
    limits: { score: number[]; year: number[] };
    data: SearchResponse[];
  };
}

export type ClientMessage = ClientDataMessage;

export enum MessageType {
  ERROR = 'ERROR',
  DATA = 'DATA',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
}

export interface WorkerErrorMessage {
  type: MessageType.ERROR;
  data: string;
}

export interface WorkerDataMessage {
  type: MessageType.DATA;
  data: SerializedNode[];
}

export interface WorkerRunningMessage {
  type: MessageType.RUNNING;
}

export interface WorkerFinishedMessage {
  type: MessageType.FINISHED;
}

export type WorkerMessage =
  | WorkerErrorMessage
  | WorkerDataMessage
  | WorkerRunningMessage
  | WorkerFinishedMessage;

// *                                    END
// *-------------------------------------------------------------------------------------

// *-------------------------------------------------------------------------------------
// *                            WORKER IMPLEMENTATION
// *-------------------------------------------------------------------------------------
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ctx: WebpackWorker<ClientMessage, WorkerMessage> = self as any;

const CHUNK_SIZE = 100;

const process = (limits: { score: number[]; year: number[] }, results: SearchResponse[]) => {
  ctx.postMessage({
    type: MessageType.RUNNING,
  });

  /*let serializedNodes = [] as SerializedNode[];
  let node = {} as SerializedNode;
  let i = 0;
  for (const { entity, score } of results) {
    node = {
      key: entity.id.toString(),
      attributes: {
        label:
          entity.authors?.[0]?.name && entity.pubDate
            ? `${entity.authors?.[0]?.name}, ${entity.year}`
            : entity.id,
        size: getNodeSizeForValue(score, limits.score[0], limits.score[1], 3, 3, 14),
        color: interpolateBlues(getNormalizedValue(entity.year, limits.year[0], limits.year[1])),
        score: score,
        important: entity.refs?.length > 5,
        category: 0,
        citationCount: entity.citationCount,
        x: Math.random(),
        y: Math.random(),
        cluster: -1, // ? Using negative ids for not existing clusters
      },
    };
    serializedNodes.push(node);

    if (i === results.length - 1 || serializedNodes.length >= CHUNK_SIZE) {
      ctx.postMessage({
        type: MessageType.DATA,
        data: serializedNodes,
      });
      serializedNodes = [];
    }
    ++i;
  }*/

  results.reduce((prev, { entity, score }, i) => {
    const node = {
      key: entity.id.toString(),
      attributes: {
        label:
          entity.authors?.[0]?.name && entity.pubDate
            ? `${entity.authors?.[0]?.name}, ${entity.year}`
            : entity.id,
        size: getNodeSizeForValue(score, limits.score[0], limits.score[1], 3, 3, 14),
        // TODO: nodes should not be white, colors should be moved in spectrum to darker colors
        color: interpolateBlues(getNormalizedValue(entity.year, limits.year[0], limits.year[1])),
        score: score,
        important: entity.refs?.length > 5,
        category: 0,
        citationCount: entity.citationCount,
        x: Math.random(),
        y: Math.random(),
        cluster: -1, // ? Using negative ids for not existing clusters
      },
    };

    prev.push(node);

    if (i === results.length - 1 || prev.length >= CHUNK_SIZE) {
      ctx.postMessage({
        type: MessageType.DATA,
        data: prev,
      });
      return [];
    }

    return prev;
  }, [] as SerializedNode[]);

  ctx.postMessage({
    type: MessageType.FINISHED,
  });
};

ctx.addEventListener('message', (event) => {
  if (event.data.action === WorkerAction.DATA) {
    process(event.data.data.limits, event.data.data.data);
  }
});
// *                                    END
// *-------------------------------------------------------------------------------------
